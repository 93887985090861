/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import PostNrComponent from "./FormComponents/PostNrComponent";
import { Row, Col, Container} from 'react-bootstrap';
import RangeComponent from "./FormComponents/RangeComponent";
import PhoneComponent from "./FormComponents/PhoneComponent";
import OrderBefaringButton from "./FormComponents/OrderBefaringButton";
import ConfirmButton from "./FormComponents/ConfirmButton";


class Tilbud extends Component {
  constructor () {
    super();
	  this.state = {
      messageId: false,
      postkode: '',
      post: '',
      area: 50,
      tlf: ''
    }
  } 

    writeToState = (record, value) =>
    {
      this.setState({
        [record]: value
      })
    }

    clearMessageId = () => {
      this.setState({
        messageId: false,
      })
    }
  
    sendForm = () => {
      const {postkode, post, area, tlf} = this.state;
      const message  = 'Hi Mirek, The client invites you for an inspection of ' + area +'m2 at ' + postkode +' ' + post +'. For more details, please call the client on ' ;
      const messageForm = {
        mobile: tlf,
        name: 'BEFARING',
        message: message,
        accept: true,
      };
      const address = {
          local: 'http://127.0.0.1:3003/api/jordansparkett/sendbefaringform', 
          global: 'https://laskowskicreative.eu/api/jordansparkett/sendbefaringform'}
      fetch(address.global, {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify(messageForm)
      })
      .then((res) => res.json())      
      .then((json) => this.setState(
          {
          messageId: json.messageId, 
          postkode: '',
          post: '',
          area: 50,
          tlf: '',
          }
          ))  
      }
  render (){
    const {area, postkode, tlf, messageId} = this.state;
    return (
        <div id='tilbud-top-beam'>
            {!messageId && <div>
                <RangeComponent value={area} record={'area'} saveValue={this.writeToState}/>
                <PostNrComponent value={postkode} record={'post'} saveValue={this.writeToState}/>
                <PhoneComponent value={tlf} record={'tlf'} saveValue={this.writeToState}/>
            </div>}
            {!messageId && <div id='order-befaring'>
                <OrderBefaringButton sendForm={this.sendForm}/>
            </div>}
            {messageId && <div>
              Takk, vår gulvslipespesialist kontakter deg snart for å avtale detaljene.
            </div>}
            {messageId && <div id='order-befaring'>
                <ConfirmButton clearMessageId={this.clearMessageId}/>
            </div>}            
        </div>
    )
}
}

export default Tilbud;
